import React, { useEffect, useState } from 'react';
import axios from 'axios';
import API_BASE_URL from "../apiConfig";

const LiveProjectData = ({ projectId }) => {

    const [liveData, setLiveData] = useState({}); 

    const fetchLiveData = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/projects/${projectId}`);
            if (response.status === 200) {
        
   
                setLiveData(response.data); 
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        fetchLiveData(); // Initial fetch
        const intervalId = setInterval(fetchLiveData, 5000); 

        return () => clearInterval(intervalId); 
    }, [projectId]);

    return (
        <tr>
            <td>{liveData.totalDuration}</td>
            <td>{liveData.liveUsers}</td>
            <td>{liveData.usersInQueue}</td>
        </tr>
    );
};

export default LiveProjectData;
