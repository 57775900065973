import React, { useEffect, useState } from "react";
import { Container, Button, Toast, Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import API_BASE_URL from "../apiConfig";

const Invoices = ({ user }) => {
  const [invoices, setInvoices] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredInvoices = invoices.filter((invoice) => {
    const projectID = invoice.projectId || "";
    const userID = invoice.userId || "";

    return (
      projectID.toLowerCase().includes(searchTerm.toLowerCase()) ||
      userID.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  useEffect(() => {
    fetchAllInvoices();
  }, []);

  const fetchAllInvoices = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/pixelStripeApi/invoices`);
      if (response.status === 200) {
        setInvoices(response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
      } else {
        console.error("Failed to fetch invoices data");
        setAlertMessage("Failed to fetch invoices data");
        setAlertVariant("danger");
        setShowMessage(true);
      }
    } catch (error) {
      console.error("Error fetching invoices:", error);
      setAlertMessage("Error fetching invoices");
      setAlertVariant("danger");
      setShowMessage(true);
    }
  };

  // Columns definition for Bootstrap Table
  const columns = [
    {
      dataField: "projectId",
      text: "Project ID",
    },
    {
      dataField: "userId",
      text: "User ID",
    },
    {
      dataField: "startAt",
      text: "Start At",
    },
    {
      dataField: "endAt",
      text: "End At",
    },
    {
      dataField: "paymentId",
      text: "Payment ID",
    },
    {
        dataField: "currency",
        text: "Currency",
      },
      {
        dataField: "amount",
        text: "Amount",
      },
    {
      dataField: "invoiceUrl",
      text: "View Invoice",
      formatter: (cellContent, row) => (
        <a href={row.invoiceUrl} target="_blank" rel="noopener noreferrer">
          View Invoice
        </a>
      ),
    },
  ];

  // Pagination options
  const options = {
    sizePerPage: 10,
    hideSizePerPage: true,
    showTotal: true,
  };

  return (
    <Container>
      <Toast
        bg={alertVariant}
        onClose={() => setShowMessage(false)}
        show={showMessage}
        delay={3000}
        autohide
      >
        <Toast.Body>{alertMessage}</Toast.Body>
      </Toast>

      <Row>
        <Col xs={2}>
          <h4>All Invoices - {invoices.length}</h4>
        </Col>

        <Col xs={4}>
          <input
            type="text"
            placeholder="Search by Project ID or User ID..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              marginBottom: "10px",
              padding: "8px",
              width: "300px",
              fontSize: "16px",
            }}
          />
        </Col>

        <Col md={{ span: 2, offset: 4 }}>
          <div style={{ textAlign: "end" }}>
            <Button onClick={fetchAllInvoices}>Refresh Data</Button>
          </div>
        </Col>
      </Row>

      <BootstrapTable
        keyField="_id"
        data={filteredInvoices}
        columns={columns}
        pagination={paginationFactory(options)}
      />
    </Container>
  );
};

export default Invoices;
