import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Form, Row, Col, Button, Container,InputGroup, Toast, ToastContainer } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import {getCountryDialCodeFromCountryCodeOrNameOrFlagEmoji,getCountryCodeFromCountryName} from 'country-codes-flags-phone-codes';
import { findFlagUrlByCountryName } from "country-flags-svg";
import mixpanel from '../mixpanel';
import data from './countries.json'
import API_BASE_URL from "../apiConfig";

let phoneCode;
let flag;

const UpdateAccountDetails = ({ user, setUser }) => {


    const [address, setAddress] = useState({
        line1: user.address ? user.address.line1 : '',
        city: user.address ? user.address.city : '',
        postalcode: user.address ? user.address.postalcode : '',
        state: user.address ? user.address.state : '',
        country: user.address ? user.address.country : ''
    });
    const [contact, setContact] = useState(user.contact || '');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [foundUs, setFoundUs] = useState(user && user.foundUs);
    const [gstin, setGstin] = useState(user && user.gstin);

    const [billingName, setBillingName] = useState(user && user.billingName);

    const [otherSource, setOtherSource] = useState(user && user.foundUs);
    
    const navigate = useNavigate();


    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );

useEffect(()=>{

    mixpanel.start_session_recording();
   
    if(address.country.length>1){
        var countryDetail = data.find(e=> e.countryName == address.country);
        var ccode = countryDetail.countryShortCode;
    phoneCode = getCountryDialCodeFromCountryCodeOrNameOrFlagEmoji(ccode);
    if(contact){
        var con = contact.split(phoneCode);
       setContact(con[1]);
    }
}
},[])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAddress(prevAddress => ({ ...prevAddress, [name]: value }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let newContact = phoneCode + contact;
        let foundUsValue;

        if(foundUs === 'Other' ){
            foundUsValue = otherSource
        }else{
            foundUsValue = foundUs
        }
        try {
            const res = await axios.put(`${API_BASE_URL}/pixelStripeApi/users/${user._id}`, {
                name: user.name,
                email: user.email,
                userId: user._id,
                address,
                contact: newContact,
                foundUs: foundUsValue,
                billingName:billingName,
                gstin:gstin,
                webIp: process.env.REACT_APP_WEBIP
            });
    
            if (res.status == 200) {
                setUser(res.data);
                setAlertMessage('User details updated successfully');
                setAlertVariant('success');
                setShowMessage(true);
    
                await delay(3000);
                setLoading(false);
                navigate('/dashboard');

                mixpanel.track('Billing Details Updated', {
                    "UserId": user._id,
                    "Response":"User details updated successfully",
                    "Reason":"success"
                  });

                  mixpanel.stop_session_recording()



            }
    
        } catch (error) {
            console.error(error);
            mixpanel.track('Billing Details Updated', {
                "UserId": user._id,
                "Response":"Failed to update user details",
                "Reason":error.response.data.message
              });
            setAlertMessage('Failed to update user details:' + "error message: "+ error.response.data.message +" status code " + error.response.status +" status text  "+ error.response.statusText);
            setAlertVariant('danger');
            setShowMessage(true);

            mixpanel.stop_session_recording()

        }
    };
    

    const handleCountryChange = (val) => {
  
         var countryDetail = data.find(e=> e.countryName == val);
         var ccode = countryDetail.countryShortCode;
         phoneCode = getCountryDialCodeFromCountryCodeOrNameOrFlagEmoji(ccode);
         flag = findFlagUrlByCountryName(val);

        setAddress({ ...address, country: val });
    };

    const handleRegionChange = (val) => {
        setAddress({ ...address, state: val });
    };

    const handleChangeBilling =(e)=>{
        console.log(e.target.value);
        setBillingName(e.target.value);
    }


    const handleChangeGstin =(e)=>{
        console.log(e.target.value);
        setGstin(e.target.value);
    }

    return (
        <Container>

{loading && (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
        <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
        <p style={{ marginTop: '10px' }}></p>
    </div>
)}

            <ToastContainer position="bottom-end">
                <Toast bg={alertVariant} onClose={() => setShowMessage(false)} show={showMessage} delay={3000} autohide>
                    <Toast.Body>{alertMessage}</Toast.Body>
                </Toast>
            </ToastContainer>

{!loading &&(
    <>
            <h5>Billing details :</h5>
            <br />
            <Form onSubmit={handleSubmit}>


                <Row>

                <Col md={4}>
                        <Form.Group controlId="billingName">
                            <Form.Label>Billing Name</Form.Label>
                            <Form.Control required type="text" name="billingName" value={billingName} onChange={handleChangeBilling} placeholder="Billing Name"  data-mixpanel-no-mask/>
                        </Form.Group>
                    </Col>
                    
                </Row>
<br/>

                <Row>


         
             

                    <Col md={4}>
                        <Form.Group controlId="line1">
                            <Form.Label>Address Line 1</Form.Label>
                            <Form.Control required type="text" name="line1" value={address.line1} onChange={handleChange} placeholder="Address Line 1"  data-mixpanel-no-mask/>
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group controlId="city">
                            <Form.Label>Address Line 2 (Optional)</Form.Label>
                            <Form.Control  type="text" name="city" value={address.city} onChange={handleChange} placeholder="Address Line 2"  data-mixpanel-no-mask/>
                        </Form.Group>
                    </Col>
             

                
                    <Col md={4}>
                    <Form.Group controlId="country">
                <Form.Label>Country</Form.Label>
                <CountryDropdown
                    required
                    value={address.country}
                    onChange={handleCountryChange}
                    classes="form-control"
                    data-mixpanel-no-mask
                />
            </Form.Group>
                    </Col>
                    </Row>

                    <br/>
                    <Row>
                    <Col md={4}>
                    <Form.Group controlId="state">
                <Form.Label>State/Province</Form.Label>
                <RegionDropdown
                    required
                    country={address.country}
                    value={address.state}
                    onChange={handleRegionChange}
                    classes="form-control"
                    data-mixpanel-no-mask
                />
            </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group controlId="postalcode">
                            <Form.Label>Postal Code</Form.Label>
                            <Form.Control required type="text" name="postalcode" value={address.postalcode} onChange={handleChange} placeholder="Postal Code"  data-mixpanel-no-mask/>
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group controlId="contact">
                            <Form.Label>Phone Number</Form.Label>
                            <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">{phoneCode}</InputGroup.Text>
        <Form.Control 
        required type="text"
         name="contact" 
         value={contact} 
         onChange={(e) => setContact(e.target.value)} placeholder="Contact"  data-mixpanel-no-mask
         />

      </InputGroup>
                        </Form.Group>
                    </Col>
                    
                    
                </Row>

                <Row>
             
                {address.country == "India" && 
                <Col md={4}>
                        <Form.Group controlId="gstin">
                            <Form.Label>GSTIN</Form.Label>
                            <Form.Control type="text" name="gstin" value={gstin} onChange={handleChangeGstin} placeholder="GSTIN"  data-mixpanel-no-mask/>
                        </Form.Group>
                    </Col>
}


             <Col md={4}>
                <Form.Group controlId="foundUs">
    <Form.Label>Where did you find us?</Form.Label>
    <Form.Control
        as="select"
        value={foundUs}
        onChange={(e) => setFoundUs(e.target.value)}
        required
        data-mixpanel-no-mask
    >
        <option value="">Select an option</option>
        <option value="Google Search">Google Search</option>
        <option value="Facebook">Facebook</option>
        <option value="LinkedIn">LinkedIn</option>
        <option value="Instagram">Instagram</option>
        <option value="Online Forum">Online Forum</option>
        <option value="Whatsapp">Whatsapp</option>
        <option value="Youtube">Youtube</option>
        <option value="Other">Other</option>
    </Form.Control>
</Form.Group>
</Col>

<Col md='4'>
{foundUs === 'Other' && (
    <Form.Group controlId="otherSource">
        <Form.Label>Please specify</Form.Label>
        <Form.Control
            type="text"
            value={otherSource}
            onChange={(e) => setOtherSource(e.target.value)}
            placeholder="Please specify"
            required
            data-mixpanel-no-mask
        />
    </Form.Group>
)}
</Col>
</Row>

                <br />
                <Button type="submit" className="mt-3">Update Details</Button>
            </Form>
            </>)
            }
        </Container>
    );
};

const UpdateAccountDetailsPage = ({ user,setUser }) => {
    return (
        <UpdateAccountDetails user={user} setUser={setUser} />
    );
};

export default UpdateAccountDetailsPage;
