import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { Container, Button, Toast, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import API_BASE_URL from "../apiConfig";


const AllProjectManual = ({ user }) => {
    const [allProjects, setAllProjects] = useState([]);
    const [alertMessage, setAlertMessage] = useState();
    const [alertVarient, setAlertVarient] = useState();
    const [showMessage, setShowMessage] = useState(false);


    const [searchTerm, setSearchTerm] = useState("");

    const filteredProjects = allProjects.filter((project) => {
        const id = project._id || ""; 
        const name = project.name || ""; 
        const email = project.userEmail || ""; 
      
        return (
          id.toLowerCase().includes(searchTerm.toLowerCase()) ||
          name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          email.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });

    useEffect(() => {
        fetchAllProjects();
    }, []);

    const fetchAllProjects = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/pixelStripeApi/projects`);
            if (response.ok) {
                const projectsData = await response.json();
                const projectsWithUserInfo = await Promise.all(projectsData.map(async project => {
                    const userResponse = await fetch(`${API_BASE_URL}/pixelStripeApi/users/${project.user}`);
                    if (userResponse.ok) {
                        const userData = await userResponse.json();
                        return {
                            ...project,
                            userEmail: userData && userData.email,
                            userName: userData && userData.name,
                            projectId: project._id,
                            shareId: project.shareId || '',
                            status: project.status,
                            subId: project.subId,
                            disableByAdmin: project.disableByAdmin,
                            subscriptionStatus: project.subscriptionStatus || '',
                            totalAmount: project.totalAmount || 0,
                            subscriptionStartDate: project.subscriptionStartDate || '',
                            subscriptionEndDate: project.subscriptionEndDate || '',
                        };
                    } else {
                        return project;
                    }
                }));

                const newData = projectsWithUserInfo
                    .filter(e =>  e.subscriptionStatus == "active" )
                    .sort((a, b) => moment(a.subscriptionEndDate) - moment(b.subscriptionEndDate));

                setAllProjects(newData);
            } else {
                console.error('Failed to fetch projects data');
            }
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    };


    
    const handleEndSub = async (projectId) => {
        const requestData = {
            userId: user._id,
            webIp: process.env.REACT_APP_WEBIP,
            projectId: projectId,
        };
    

        console.log(requestData);

        try {
          const response = await axios.post(`${API_BASE_URL}/pixelStripeApi/projects/end-subscription`, requestData);
    
          console.log('Response:', response.data);
          alert(' ended successfully!');
    
        } catch (error) {
          // Handle error
          console.error('Error ending', error);
          alert('Failed to end .');
        }
      };

    const saveProject = async (project) => {
        project.userId = user._id;
        project.webIp = process.env.REACT_APP_WEBIP;

        try {
            const response = await fetch(`${API_BASE_URL}/pixelStripeApi/projects/${project._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(project)
            });

            if (response.ok) {
                fetchAllProjects();
            } else {
                console.error('Failed to save project:', project);
            }
        } catch (error) {
            console.error('Error saving project:', project, error);
        }
    };

    const columns = [
        { dataField: 'name', text: 'Name', editable: false },
        { dataField: 'userName', text: 'User Name', editable: false },
        {
            dataField: 'subscriptionStartDate',
            text: 'Start Date',
            formatter: (cell) => {
                if (!cell) return '';
                const dateObj = new Date(cell);
                return `${('0' + dateObj.getUTCDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${dateObj.getUTCFullYear()}`;
            },
            editor: { type: Type.DATE }
        },
        {
            dataField: 'subscriptionEndDate',
            text: 'End Date',
            formatter: (cell) => {
                if (!cell) return '';
                const dateObj = new Date(cell);
                return `${('0' + dateObj.getUTCDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${dateObj.getUTCFullYear()}`;
            },
            editor: { type: Type.DATE }
        },
    
 
        { dataField: 'subId', text: 'Subscription Id' },

        {
            dataField: 'actions',
            text: 'Actions',
            editable: false,  
            formatter: (cellContent, row) => (
                <div>
                    <Button
                        variant="danger"
                        onClick={() => handleEndSub(row._id)}
                    >
                        End
                    </Button>
                    {/* Add other actions here */}
                </div>
            )
        }
    ];

    const cellEdit = cellEditFactory({
        mode: 'click',
        blurToSave: true,
        afterSaveCell: (oldValue, newValue, row, column) => {
            if (column.text === 'Status') {
                row.status = newValue === "true";
            }

            if (column.text === 'Disable By Admin') {
                row.disableByAdmin = newValue === "true";
            }

            saveProject(row);
        }
    });

    return (
        <Container>
            <Toast bg={alertVarient} onClose={() => setShowMessage(false)} show={showMessage} delay={3000} autohide>
                <Toast.Header></Toast.Header>
                <Toast.Body>{alertMessage}</Toast.Body>
            </Toast>
            <Row>
                <Col xs={3}><h4>Active Subscriptions</h4></Col>

                <Col xs = {4}>
      <input
        type="text"
        placeholder="Search by name..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{
          marginBottom: "10px",
          padding: "8px",
          width: "300px",
          fontSize: "16px",
        }}
      />
                </Col>

                <Col md={{ span: 2 }}>
                    <div style={{ textAlign: 'end' }}>
                      <h3>Total:- {filteredProjects.length}</h3>
                    </div>
                </Col>

                <Col md={{ span: 2 }}>
                    <div style={{ textAlign: 'end' }}>
                        <Button onClick={fetchAllProjects}>Refresh Data</Button>
                    </div>
                </Col>
            </Row>
            <BootstrapTable
                keyField="_id"
                data={filteredProjects.map(project => ({
                    ...project,
                    raiseInvoice: '...Loading'
                }))}
                columns={columns}
                cellEdit={cellEdit}
                pagination={paginationFactory({
                    sizePerPage: 10, // Customize page size
                    hideSizePerPage: false, // Show the size-per-page dropdown
                    showTotal: true, // Show total record count
                    alwaysShowAllBtns: true, // Show navigation buttons
                })}
            />
        </Container>
    );
};

export default AllProjectManual;
