import React, { useState, useEffect } from "react";
import axios from "axios";
import "./StatusTracker.css";
import API_BASE_URL from "../apiConfig";

const StatusTracker = ({ projectId, distributeToServer, statusSTeps }) => {
  const [currentStep, setCurrentStep] = useState();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [isFlipping, setIsFlipping] = useState(false);
  const [error, setError] = useState(null); // Added error state

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));


  useEffect(() => {
    
     const fetchStatus = async () => {

      try {
        const response = await axios.get(
          `${API_BASE_URL}/pixelStripeApi/projects/${projectId}`
        );


        
        let storedItemsDist = JSON.parse(localStorage.getItem("fileDataDistribute")) || [];

        const existsDist = storedItemsDist.some(item => item.id === projectId);


        if (existsDist) {
          var fileId  = storedItemsDist.filter(item => item.id === projectId);

          var fileObjDist = fileId[0];

          const objFile = response.data.files.filter(
            (e) => e._id === fileObjDist.readyFileId
          );

          var fileObjDistNew = objFile[0];

          setCurrentStep(fileObjDistNew.status);

          if (["Approved"].includes(fileObjDistNew.status)) {


                      storedItemsDist = storedItemsDist.filter(item => item.id !== projectId);

                   

                      localStorage.setItem("fileDataDistribute", JSON.stringify(storedItemsDist));

            await delay(2000);
            window.location.reload(true);
        }

        }
        let liveFileObj = response.data.files[response.data.files.length - 1];


        let storedItems = JSON.parse(localStorage.getItem("fileData")) || [];
        const exists = storedItems.some(item => item.id === projectId);


        if (exists) {
          if (["Approved", "Ready", "Reject"].includes(liveFileObj.status)) {

           
storedItems = storedItems.filter(item => item.id !== projectId);

localStorage.setItem("fileData", JSON.stringify(storedItems));

            await delay(2000);
            window.location.reload(true);
          } else {

            if (statusSTeps.includes(liveFileObj.status)) {


              if (liveFileObj.status === "pending") {


                setCurrentStep("queued");
              } else {


                setCurrentStep(liveFileObj.status);
              }
            }
          }
        }
        
      } catch (error) {
        console.error("Error fetching status:", error);
        setError("Failed to fetch status. Please try again later.");
      }
    };
  

    const interval = setInterval(fetchStatus, 2000);
  
    return () => {
     
      clearInterval(interval);
    };
  }, []);


  useEffect(() => {
    if (statusSTeps.length > 0 && currentStep && !distributeToServer) {
      setIsFlipping(true);
      setTimeout(() => {
        const stepIndex = statusSTeps.findIndex((step) => step === currentStep);
        setCurrentStepIndex(stepIndex);
        setIsFlipping(false);
      }, 600);
    }
  }, [currentStep, statusSTeps]);

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="flip-box-container">
      <div className={`flip-box ${isFlipping ? "flipping" : ""}`}>
        <div className="step-content">
         
            <div className="step-label"> [{currentStepIndex+1}/{statusSTeps.length}] {statusSTeps[currentStepIndex]}</div>
          <div className="dots-loader">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusTracker;
